<template>
  <v-container>
    <v-btn outlined rounded color="#173c93" :to="getBackRoute()">
      Voltar
    </v-btn>

    <read-article :article="foundCompliance" />
  </v-container>
</template>

<script>
import { externalBySlug } from "../../services/compliance-service";

export default {
  name: "ReadCompliance",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from;
    });
  },
  data() {
    return {
      foundCompliance: {},
      previousRoute: { fullPath: "" },
    };
  },
  async created() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.foundCompliance = await externalBySlug(this.$route.params.slug);
  },
  methods: {
    getBackRoute() {
      if (this.previousRoute.fullPath.includes("compliance")) {
        return this.previousRoute.fullPath;
      } else {
        return "/compliance";
      }
    },
  },
};
</script>
